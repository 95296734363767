import Vue from 'vue'
import VueRouter from 'vue-router'
import { Route } from 'vue-router/types/router' // todo
import Home from '@/views/Home.vue'
import View404 from '@/views/View404.vue'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/About.vue'),
  },
  {
    path: '/familles',
    name: 'families-list',
    component: () => import('@/views/Entities/Entities.vue'),
    props: { isFamily: true, },
  },
  {
    path: '/familles-a-archiver',
    name: 'archive-families',
    component: () => import('@/views/Tools/ArchiveFamilies.vue'),
    props: {},
  },
  {
    path: '/familles/:entityId',
    name: 'families-detail',
    component: () => import('@/views/Entities/Entity.vue'),
    props: (route: Route) => ({ isFamily: true, entityId: route.params.entityId, }),
  },
  {
    path: '/contacts',
    name: 'entities-list',
    component: () => import('@/views/Entities/Entities.vue'),
    props: { isFamily: false, },
  },
  {
    path: '/contacts/:entityId',
    name: 'entities-detail',
    component: () => import('@/views/Entities/Entity.vue'),
    props: (route: Route) => ({ isFamily: false, entityId: route.params.entityId, }),
  },
  {
    path: '/nouveau-contact',
    name: 'new-contact',
    component: () => import('@/views/Entities/AddNewEntity.vue'),
    props: {},
  },
  {
    path: '/individus',
    name: 'single-individuals',
    component: () => import('@/views/Entities/SingleIndividuals.vue'),
  },
  {
    path: '/inscrits-newsletter',
    name: 'newsletter-individuals',
    component: () => import('@/views/Entities/NewsletterIndividuals.vue'),
  },
  {
    path: '/groupes-age-personnalises',
    name: 'children-with-custom-age-groups',
    component: () => import('@/views/Family/ChildrenWithCustomAgeGroups.vue'),
    props: true,
  },
  {
    path: '/pages/:slug',
    name: 'page-detail',
    component: () => import('@/views/Pages/Page.vue'),
    props: true,
  },
  {
    path: '/liste-individus/:id',
    name: 'tag-detail',
    component: () => import('@/views/Tags/Tag.vue'),
    props: true,
  },
  {
    path: '/cartes',
    name: 'adhesion-types-list',
    component: () => import('@/views/Adhesions/AdhesionTypes.vue'),
  },
  {
    path: '/users',
    name: 'users-list',
    component: () => import('@/views/Users/Users.vue'),
  },
  {
    path: '/activites',
    name: 'activities-list',
    component: () => import('@/views/Activities/Activities.vue'),
    props: { youthHome: 0, },
  },
  {
    path: '/activites/ajout',
    name: 'activity-add',
    component: () => import('@/views/Activities/AddActivity.vue'),
    props: { youthHome: 0, },
  },
  {
    path: '/activites/:activityId',
    name: 'activities-detail',
    component: () => import('@/views/Activities/Activity.vue'),
    props: (route: Route) => ({ activityId: route.params.activityId, youthHome: 0, }),
  },
  {
    path: '/activites/:youthHome/:activityId/dates',
    name: 'activity-dates',
    component: () => import('@/views/Activities/ActivityDates.vue'),
    props: (route: Route) => (
      { activityId: route.params.activityId, youthHome: route.params.youthHome, }
    ),
  },
  {
    path: '/activites/:youthHome/:activityId/flyer',
    name: 'activity-flyer',
    component: () => import('@/views/Activities/ActivityFlyer.vue'),
    props: (route: Route) => (
      { activityId: route.params.activityId, youthHome: route.params.youthHome, }
    ),
  },
  {
    path: '/animateurs',
    name: 'activity-managers',
    component: () => import('@/views/Activities/ActivityManagers.vue'),
    props: true,
  },
  {
    path: '/occupation-des-lieux',
    name: 'agenda-activity-dates',
    component: () => import('@/views/Agenda/PlacesAgenda.vue'),
    props: true,
  },
  {
    path: '/planning-des-activites',
    name: 'agenda-activities',
    component: () => import('@/views/Agenda/ActivitiesAgenda.vue'),
    props: true,
  },
  {
    path: '/agenda-du-jour',
    name: 'agenda-day-dates',
    component: () => import('@/views/Agenda/DailyAgenda.vue'),
    props: true,
  },
  {
    path: '/inscrits-aux-activites',
    name: 'multi-activities-inscriptions',
    component: () => import('@/views/Activities/MultiActivitiesInscriptions.vue'),
    props: true,
  },
  {
    path: '/donnees-portail-a-confirmer',
    name: 'confirm-portal-data',
    component: () => import('@/views/FamilyPortal/ConfirmPortalData.vue'),
    props: true,
  },
  {
    path: '/inscriptions-activites-a-l-essai',
    name: 'trying-activity-inscriptions',
    component: () => import('@/views/Activities/TryingActivityInscriptions.vue'),
    props: true,
  },
  {
    path: '/activites/:activityId/:youthHome/paiements',
    name: 'activities-payments',
    component: () => import('@/views/Activities/ActivityPayments.vue'),
    props: (route: Route) => ({ activityId: route.params.activityId, youthHome: route.params.youthHome, }),
  },
  {
    path: '/activites/:activityId/seances/:youthHome',
    name: 'activities-seances',
    component: () => import('@/views/Activities/ActivitySeances.vue'),
    props: (route: Route) => ({ activityId: route.params.activityId, youthHome: route.params.youthHome, }),
  },
  {
    path: '/autorisations-activites',
    name: 'activities-authorizations',
    component: () => import('@/views/Activities/Authorizations/ActivitiesAuthorizations.vue'),
    props: { youthHome: 0, },
  },
  {
    path: '/autorisations-accueil-de-loisirs',
    name: 'youth-home-authorizations',
    component: () => import('@/views/Activities/Authorizations/ActivitiesAuthorizations.vue'),
    props: { youthHome: 1, },
  },
  {
    path: '/accueil-de-loisirs/adherents/:activityId',
    name: 'youth-homes-detail',
    component: () => import('@/views/Activities/Activity.vue'),
    props: (route: Route) => ({ activityId: route.params.activityId, youthHome: 1, }),
  },
  {
    path: '/seances',
    name: 'seances-config',
    component: () => import('@/views/YouthHome/SeancesConfig.vue'),
    props: true,
  },
  {
    path: '/frequentations-acm',
    name: 'youth-inscriptions-summary',
    component: () => import('@/views/YouthHome/YouthHomeInscriptionsSummary.vue'),
    props: true,
  },
  {
    path: '/accueil-de-loisirs/:day?',
    name: 'youth-homes-list',
    component: () => import('@/views/YouthHome/YouthHomes.vue'),
    props: true,
  },
  {
    path: '/verification-pointage',
    name: 'clocking-checking',
    component: () => import('@/views/YouthHome/ClockingChecking.vue'),
    props: true,
  },
  {
    path: '/facturation-creche',
    name: 'nursery-invoicing',
    component: () => import('@/views/Invoices/MultiInvoicing.vue'),
    props: { appName: 'nursery', },
  },
  {
    path: '/verification-creche',
    name: 'nursery-checking',
    component: () => import('@/views/Nursery/CheckNurseryInscriptions.vue'),
  },
  {
    path: '/facturation-accueil-de-loisirs',
    name: 'youth-invoicing',
    component: () => import('@/views/Invoices/MultiInvoicing.vue'),
    props: { appName: 'youth', },
  },
  {
    path: '/facturation-adhesions',
    name: 'adhesions-invoicing',
    component: () => import('@/views/Invoices/MultiInvoicing.vue'),
    props: { appName: 'adhesions', },
  },
  {
    path: '/facturation-activites',
    name: 'activities-invoicing',
    component: () => import('@/views/Invoices/MultiInvoicing.vue'),
    props: { appName: 'activities', },
  },
  {
    path: '/seances/:day/:youthHome/:seanceType/:seancePeriod',
    name: 'youth-homes-day',
    component: () => import('@/views/YouthHome/YouthHomeDay.vue'),
    props: true,
  },
  {
    path: '/week-seances/:day/:youthHome/:seanceType/:seancePeriod',
    name: 'youth-homes-week',
    component: () => import('@/views/YouthHome/YouthHomeWeek.vue'),
    props: true,
  },
  {
    path: '/atelier/:day/:listType/:id',
    name: 'workshop-daily-inscriptions',
    component: () => import('@/views/YouthHome/WorkshopDailyInscriptions.vue'),
    props: true,
  },
  {
    path: '/ecoles',
    name: 'schools-list',
    component: () => import('@/views/School/SchoolsList.vue'),
    props: true,
  },
  {
    path: '/villes-rues-et-quartiers',
    name: 'streets-list',
    component: () => import('@/views/Admin/StreetsList.vue'),
    props: true,
  },
  {
    path: '/periodes',
    name: 'seance-periods-list',
    component: () => import('@/views/YouthHome/SeancePeriodsList.vue'),
    props: true,
  },
  {
    path: '/types-de-seance',
    name: 'seance-types-list',
    component: () => import('@/views/YouthHome/SeanceTypesList.vue'),
    props: true,
  },
  {
    path: '/niveaux-scolaires',
    name: 'school-levels-list',
    component: () => import('@/views/School/SchoolLevelsList.vue'),
    props: true,
  },
  {
    path: '/inscriptions-a-confirmer',
    name: 'confirm-inscriptions',
    component: () => import('@/views/FamilyPortal/ConfirmInscriptions.vue'),
    props: true,
  },
  {
    path: '/confirmations-inscriptions',
    name: 'inscription-confirmations-list',
    component: () => import('@/views/FamilyPortal/InscriptionConfirmationsList.vue'),
    props: true,
  },
  {
    path: '/factures',
    name: 'invoices-synthesis',
    component: () => import('@/views/Invoices/Invoices.vue'),
    props: true,
  },
  {
    path: '/a-facturer',
    name: 'invoices-to-do',
    component: () => import('@/views/Invoices/InvoicesToDo.vue'),
    props: true,
  },
  {
    path: '/synthese-impayes',
    name: 'unpaid-analytics',
    component: () => import('@/views/Invoices/UnpaidAnalytics.vue'),
    props: true,
  },
  {
    path: '/paiements',
    name: 'payments',
    component: () => import('@/views/Payments/Payments.vue'),
    props: true,
  },
  {
    path: '/avoirs',
    name: 'credits',
    component: () => import('@/views/Payments/Credits.vue'),
    props: true,
  },
  {
    path: '/contributions',
    name: 'contributions',
    component: () => import('@/views/Payments/Contributions.vue'),
    props: true,
  },
  {
    path: '/depots',
    name: 'deposits',
    component: () => import('@/views/Deposits/Deposits.vue'),
    props: true,
  },
  {
    path: '/depots/:depositId',
    name: 'deposit-detail',
    component: () => import('@/views/Deposits/Deposit.vue'),
    props: true,
  },
  {
    path: '/factures-a-payer',
    name: 'invoices-to-pay',
    component: () => import('@/views/Invoices/InvoicesList.vue'),
    props: { paid: 0, },
  },
  {
    path: '/factures-payees',
    name: 'invoices-paid',
    component: () => import('@/views/Invoices/InvoicesList.vue'),
    props: { paid: 1, },
  },
  {
    path: '/facture/:invoiceId',
    name: 'invoice-detail',
    component: () => import('@/views/Invoices/Invoice.vue'),
    props: true,
  },
  {
    path: '/compta-analytique',
    name: 'analytic-accounting',
    component: () => import('@/views/Accounting/AnalyticAccountingEx.vue'),
    props: true,
  },
  {
    path: '/compta-analytique-avoirs',
    name: 'analytic-accounting-credits',
    component: () => import('@/views/Accounting/AnalyticAccountingCredits.vue'),
    props: true,
  },
  {
    path: '/compta-analytique-complet',
    name: 'analytic-accounting-full',
    component: () => import('@/views/Accounting/AnalyticAccounting.vue'),
    props: true,
  },
  {
    path: '/exports-facturation',
    name: 'invoices-exports',
    component: () => import('@/views/Invoices/InvoicesExports.vue'),
    props: true,
  },
  {
    path: '/contact-membres/',
    name: 'contact-members',
    component: () => import('@/views/Contact/ContactMembers.vue'),
    props: true,
  },
  {
    path: '/contact-familles/',
    name: 'contact-entities',
    component: () => import('@/views/Contact/ContactEntities.vue'),
    props: true,
  },
  {
    path: '/annees-scolaire/',
    name: 'SchoolYearsList',
    component: () => import('@/views/School/SchoolYearsList.vue'),
    props: true,
  },
  {
    path: '/statistiques',
    name: 'stats-home',
    component: () => import('@/views/Stats/StatsHome.vue'),
    props: true,
  },
  {
    path: '/statistiques/accueil-de-loisirs',
    name: 'stats-youth-home',
    component: () => import('@/views/Reports/YouthHomeReports.vue'),
    props: { detail: false, },
  },
  {
    path: '/statistiques/inscrits-accueils-de-loisirs',
    name: 'stats-youth-inscriptions',
    component: () => import('@/views/Stats/MembersByActivitiesStats.vue'),
    props: { youth: 1, },
  },
  {
    path: '/statistiques/inscrits-activites',
    name: 'stats-activity-inscriptions',
    component: () => import('@/views/Stats/MembersByActivitiesStats.vue'),
    props: { youth: 0, },
  },
  {
    path: '/statistiques/ventes-activites',
    name: 'stats-activity-sales',
    component: () => import('@/views/Stats/SalesByActivitiesStats.vue'),
    props: { youth: 0, },
  },
  {
    path: '/statistiques/ventes-acm',
    name: 'stats-youth-sales',
    component: () => import('@/views/Stats/SalesByActivitiesStats.vue'),
    props: { youth: 1, },
  },
  {
    path: '/statistiques/frequentations-accueil-de-loisirs',
    name: 'stats-youth-attendance',
    component: () => import('@/views/Stats/YouthAttendance.vue'),
    props: true,
  },
  {
    path: '/statistiques/gestion-accueil-de-loisirs',
    name: 'stats-youth-management',
    component: () => import('@/views/Stats/YouthManagement.vue'),
    props: true,
  },
  {
    path: '/statistiques/aides-accueil-de-loisirs',
    name: 'stats-youth-welfare',
    component: () => import('@/views/Stats/YouthWelfareList.vue'),
    props: true,
  },
  {
    path: '/statistiques/forfaits-accueil-de-loisirs',
    name: 'stats-youth-fixed-fee',
    component: () => import('@/views/Stats/YouthFixedFeeList.vue'),
    props: true,
  },
  {
    path: '/statistiques/frequentations-liste',
    name: 'stats-youth-attendance-list',
    component: () => import('@/views/Stats/YouthAttendanceList.vue'),
    props: true,
  },
  {
    path: '/statistiques/membres-par-age',
    name: 'stats-members-by-age',
    component: () => import('@/views/Stats/MembersByAgeAndGenderStats.vue'),
    props: true,
  },
  {
    path: '/statistiques/membres-par-lieu',
    name: 'stats-members-by-location',
    component: () => import('@/views/Stats/MembersByLocationStats.vue'),
    props: true,
  },
  {
    path: '/statistiques/accueil-de-loisirs/detail',
    name: 'stats-youth-home-detail',
    component: () => import('@/views/Reports/YouthHomeReports.vue'),
    props: { detail: true, },
  },
  {
    path: '/rapport-jeunesse',
    name: 'stats-youth-report',
    component: () => import('@/views/Reports/YouthReport.vue'),
  },
  {
    path: '/rapport-communes',
    name: 'stats-city-report',
    component: () => import('@/views/Reports/CityReport.vue'),
  },
  {
    path: '/statistiques/etat-des-ventes',
    name: 'stats-monthly-sales',
    component: () => import('@/views/Stats/StatsMonthlySales.vue'),
    props: true,
  },
  {
    path: '/statistiques/composition-des-familles',
    name: 'stats-family-compositions',
    component: () => import('@/views/Stats/FamilyCompositions.vue'),
    props: true,
  },
  {
    path: '/portail-familles',
    name: 'family-portal-home',
    component: () => import('@/views/FamilyPortal/Home.vue'),
    props: true,
  },
  {
    path: '/portail-familles/membres',
    name: 'family-portal-members',
    component: () => import('@/views/FamilyPortal/AccountsList.vue'),
    props: true,
  },
  {
    path: '/portail-familles/ouvertures',
    name: 'family-portal-openings',
    component: () => import('@/views/FamilyPortal/Openings.vue'),
    props: true,
  },
  {
    path: '/caisse',
    name: 'cash-logs',
    component: () => import('@/views/Cash/CashLogs.vue'),
    props: true,
  },
  {
    path: '/comptes-analytiques',
    name: 'analytic-accounts',
    component: () => import('@/views/Accounting/FinancialAccounts.vue'),
    props: { modelName: 'analytic', },
  },
  {
    path: '/comptes-generaux',
    name: 'general-accounts',
    component: () => import('@/views/Accounting/FinancialAccounts.vue'),
    props: { modelName: 'general', },
  },
  {
    path: '/admin',
    name: 'admin-home',
    component: () => import('@/views/Admin/AdminHome.vue'),
    props: true,
  },
  {
    path: '/liste-des-lieux',
    name: 'tools-places',
    component: () => import('@/views/Tools/PlacesList.vue'),
    props: true,
  },
  {
    path: '/outils',
    name: 'tools-home',
    component: () => import('@/views/Tools/ToolsHome.vue'),
    props: true,
  },
  {
    path: '/renouvellement-inscriptions',
    name: 'renew-activity-inscriptions',
    component: () => import('@/views/Tools/RenewActivityInscriptions.vue'),
    props: true,
  },
  {
    path: '/attestation-fiscale',
    name: 'yearly-tax-certificates',
    component: () => import('@/views/Tools/YearlyTaxCertificates.vue'),
    props: true,
  },
  {
    path: '/justificatifs',
    name: 'certificates',
    component: () => import('@/views/Certificates/Certificates.vue'),
    props: true,
  },
  {
    path: '/justificatifs-par-familles',
    name: 'certificates-by-entities',
    component: () => import('@/views/Certificates/CertificatesByEntities.vue'),
    props: true,
  },
  {
    path: '/notes',
    name: 'notes',
    component: () => import('@/views/Notes/Notes.vue'),
    props: true,
  },
  {
    path: '/publications',
    name: 'publications-home',
    component: () => import('@/views/Publications/PublicationsHome.vue'),
    props: true,
  },
  {
    path: '/nouvelle-publication',
    name: 'publications-add',
    component: () => import('@/views/Publications/EditPublication.vue'),
    props: true,
  },
  {
    path: '/edit-publication/:publicationId',
    name: 'publications-edit',
    component: () => import('@/views/Publications/EditPublication.vue'),
    props: true,
  },
  {
    path: '/ludotheque',
    name: 'store-articles',
    component: () => import('@/views/Store/Store.vue'),
    props: true,
  },
  {
    path: '/inventaire-ludotheque',
    name: 'store-items',
    component: () => import('@/views/Store/StoreItems.vue'),
    props: true,
  },
  {
    path: '/ludotheque/ajout-jeu',
    name: 'store-add-article',
    component: () => import('@/views/Store/AddArticle.vue'),
    props: true,
  },
  {
    path: '/ludotheque/jeu/:articleId',
    name: 'store-article-detail',
    component: () => import('@/views/Store/ArticleDetail.vue'),
    props: true,
  },
  {
    path: '/creche/:day?',
    name: 'nursery-home',
    component: () => import('@/views/Nursery/NurseryHome.vue'),
    props: true,
  },
  {
    path: '/inscriptions-creche/:nurseryId/:day?',
    name: 'nursery-inscriptions',
    component: () => import('@/views/Nursery/NurseryInscriptions.vue'),
    props: true,
  },
  {
    path: '/frequentation-creche',
    name: 'nursery-attendance',
    component: () => import('@/views/Nursery/NurseryAttendance.vue'),
    props: true,
  },
  {
    path: '/contrats-creche',
    name: 'nursery-contracts',
    component: () => import('@/views/Nursery/NurseryContracts.vue'),
    props: true,
  },
  {
    path: '/rapport-creche',
    name: 'nursery-report',
    component: () => import('@/views/Nursery/NurseryReport.vue'),
    props: true,
  },
  {
    path: '/journaux-de-bord',
    name: 'logbooks',
    component: () => import('@/views/LogBook/LogBook.vue'),
    props: true,
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login/Login.vue'),
  },
  {
    path: '/azure-login',
    name: 'azure-login',
    component: () => import('@/views/Login/AzureLogin.vue'),
  },
  { path: '*', component: View404, }
]

// évite l'erreur NavigationDuplicated
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function(location: any, onComplete?: any, onAbort?: any): any {
  originalPush.call(
    this,
    location,
    onComplete,
    function(error) {
      if (error && (error.name !== 'NavigationDuplicated')) {
        if (onAbort) {
          onAbort(error)
        } else {
          console.error('navigation error', error) // eslint-disable-line no-console
        }
      }
    }
  )
}

const router: VueRouter = new VueRouter(
  {
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
  }
)

router.beforeEach((to : Route, from : Route, next : any) => {
  document.title = 'millibase.net'
  if (to.path !== '/login') {
    if (store.getters.isAuthenticated) {
      next()
    } else {
      next('/login')
    }
  } else {
    next()
  }
})

export default router
