<template>
  <div>
    <b-form @submit.prevent="onSave" v-if="showForm && hasPerm('families.change_child')">
      <div class="field-group-block">
        <div class="field-group-header">
          <b>Enfant</b>
        </div>
        <div class="field-line">
          <b-row>
            <b-col v-if="schools.length">
              <b-form-group
                id="school-class-group"
                label="Classe"
                label-for="schoolClass"
                description=""
              >
                <b-form-select
                  id="schoolClass"
                  v-model="childData.schoolClass"
                >
                  <b-form-select-option :value="null"></b-form-select-option>
                  <b-form-select-option-group
                    :label="school.fullName()"
                    :value="school"
                    v-for="school in schools"
                    :key="school.name"
                  >
                    <b-form-select-option
                      :value="elt"
                      v-for="elt in school.schoolClasses"
                      :key="elt.id"
                    >
                      {{ elt.fullName() }}
                    </b-form-select-option>
                  </b-form-select-option-group>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col v-if="schoolLevels.length">
              <b-form-group
                id="schoolLevels-group"
                label="Niveau scolaire"
                label-for="schoolLevel"
                description=""
              >
                <b-form-select
                  id="schoolLevel"
                  v-model="childData.schoolLevel"
                >
                  <b-form-select-option :value="elt" v-for="elt in schoolLevels" :key="elt.id">
                    {{ elt.name }}
                  </b-form-select-option>
                </b-form-select>
                <div class="small2" v-if="individual.birthDate && schoolYears.length">
                  Calculer le niveau à partir de l'age de l'enfant
                  <span v-for="schoolYear in schoolYears" :key="schoolYear.id">
                    <a href @click.prevent="calculateSchoolLevel(schoolYear.startYear)"
                       class="btn btn-xs btn-secondary" style="margin-right: 2px"
                    >
                      en {{ schoolYear.startYear }}
                    </a>
                  </span>
                </div>
              </b-form-group>
            </b-col>
            <b-col v-if="childData.ageGroup || !child.hideCustomAgeGroup">
              <b-form-group
                id="age-group-group"
                label="Groupe d'age"
                label-for="ageGroup"
                description="Forcer un groupe d'age différent de celui de l'age réel de l'enfant"
              >
                <div class="reset-age-group">
                  <a href @click.prevent="childData.ageGroup = null">Supprimer</a>
                </div>
                <b-form-input
                  id="ageGroup"
                  v-model="childData.ageGroup"
                  type="number"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div v-if="childData.ageGroup" class="field-line">
          <b-row>
            <b-col>
              <b-form-group
                label-for="ageGroupFrom"
                description="Pour les séances entre ces dates."
                label="Groupe d'âge à partir du"
              >
                <b-form-input
                  id="ageGroupFrom"
                  v-model="childData.ageGroupFrom"
                  type="date"
                  class="small-input"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label-for="ageGroupTo"
                label="jusqu'au"
                description="si vide, pour toutes les séances"
              >
                <b-form-input
                  id="ageGroupTo"
                  v-model="childData.ageGroupTo"
                  type="date"
                  class="small-input"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div
          class="field-line"
          v-if="!child.hideDiscountPercentage || tariffs.length || welfareCategories.length"
        >
          <b-row>
            <b-col>
              <b-form-group
                id="discountPercentage-group"
                label="Réduction en %"
                label-for="discountPercentage"
                description="Valeur de la réduction"
                v-if="!child.hideDiscountPercentage"
              >
                <b-input
                  id="discountPercentage"
                  v-model="childData.discountPercentage"
                  type="number"
                  min="0"
                  max=100
                  step="0.01"
                ></b-input>
              </b-form-group>
            </b-col>
            <b-col v-if="tariffs.length > 0">
              <b-form-group
                id="tariff-group"
                label="Tarif spécial"
                label-for="specialTariff"
                description=""
              >
                <b-form-select
                  id="specialTariff" v-model="childData.specialTariff"
                >
                  <b-form-select-option :value="elt" v-for="elt in tariffs" v-bind:key="elt.id">
                    {{ elt.name }}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col v-if="welfareCategories.length > 0">
              <b-form-group
                id="welfare-categories-group"
                label="Aide aux vacances"
                label-for="welfareCategory"
                description=""
              >
                <b-form-select
                  id="welfareCategory" v-model="childData.welfareCategory"
                >
                  <b-form-select-option :value="elt" v-for="elt in welfareCategories" v-bind:key="elt.id">
                    {{ elt.name }}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div class="field-line" v-if="!child.hideChildWarnings">
          <b-row>
            <b-col>
              <b-form-group
                id="warnings-group"
                label="Message enfant"
                label-for="warnings"
                description="Affiché sur le listing journalier: Précautions, infos particulières... ">
                <b-textarea
                  id="warnings"
                  v-model="childData.warnings"
                ></b-textarea>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div class="field-line">
          <b-row>
            <b-col v-if="!child.hidePersonalHelp">
              <b-form-group
                label=""
                label-for="personal-help"
                description="Attention. le PAI ne donne plus droit à la prestation majorée"
              >
                <b-checkbox
                  v-model="childData.personalHelp"
                  :value="true"
                  :unchecked-value="false"
                  id="personal-help"
                  name="personal-help"
                >
                  PAI lié au handicap
                </b-checkbox>
               </b-form-group>
            </b-col>
            <b-col>
               <b-form-group
                label=""
                label-for="handicap-help"
                description="Informations pour votre déclaration CAF"
              >
                <b-checkbox
                  v-model="childData.handicapHelp"
                  :value="true"
                  :unchecked-value="false"
                  id="handicap-help"
                  name="handicap-help"
                >
                  Allocation Éducation Enfant Handicapé (AEEH)
                </b-checkbox>
              </b-form-group>
              <div v-if="childData.handicapHelp">
                <b-row>
                  <b-col>
                    <b-form-group
                      label="Du"
                      label-for="handicap-help_startsOn"
                    >
                      <b-input
                        type="date"
                        v-model="childData.handicapHelpStartsOn"
                        :value="true"
                        :unchecked-value="false"
                        id="handicap-help_startsOn"
                        class="small-input"
                      >
                      </b-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      label="au"
                      label-for="handicap-help_startsOn"
                    >
                      <b-input
                        type="date"
                        v-model="childData.handicapHelpEndsOn"
                        :value="true"
                        :unchecked-value="false"
                        id="handicap-help_endsOn"
                        class="small-input"
                      >
                      </b-input>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </div>
        <fields-detail
          v-if="member"
          :show-individual="true"
          :individual="member.individual"
          :role="member.role"
          :is-family="true"
          :included="true"
          :force-edit="true"
          :force-save="forceSave"
          @saved="onFieldsSaved"
        >
        </fields-detail>
        <b-row class="buttons-bar">
          <b-col class="text-left">
          </b-col>
          <b-col class="text-right">
            <a class="btn btn-secondary" href @click.prevent="onCancel">Annuler</a>
            <b-button type="submit" variant="primary" class="btn-save-child">Enregistrer</b-button>
          </b-col>
        </b-row>
      </div>
    </b-form>

    <div v-if="!showForm && hasPerm('families.view_child')">
      <div class="field-group-block">
        <div class="field-group-header">
          <b-row>
            <b-col cols="9">
              <b>Enfant</b>
            </b-col>
            <b-col cols="3" class="text-right">
              <a
                class="btn btn-primary btn-sm btn-edit-child"
                href
                @click.prevent="onEdit()"
                :class="editDisabled ? 'disabled' : ''"
                v-if="hasPerm('families.change_child') && !readOnly"
              >
                <i class="fa fa-edit"></i> Modifier
              </a>
            </b-col>
          </b-row>
        </div>
        <div class="field-line" v-if="schools.length">
          <b><i class="fas fa-chalkboard-teacher" /> Classe: </b>
          <span v-if="child.schoolClass && child.schoolClass.isActive">
            {{ child.schoolClass.fullName() | defaultValue('-') }}
            <div class="small2" v-if="child.schoolClassUpdatedOn">
              mis à jour le {{ child.schoolClassUpdatedOn | dateToString('DD/MM/YYYY') }}
            </div>
          </span>
          <span v-else>-</span>
        </div>
        <div class="field-line" v-if="child.hasSchoolLevel">
          <b><i class="fas fa-school" /> Niveau scolaire: </b>
          <span v-if="child.schoolLevel && child.schoolLevel.id">
            {{ child.schoolLevel.name | defaultValue('-') }}
          </span>
          <span v-else>-</span>
        </div>
        <div
          class="field-line"
          :class="child.ageGroup ? 'child-warnings-active' : ''"
          v-if="child.ageGroup || !child.hideCustomAgeGroup"
        >
          <b><i class="fas fa-child" /> Groupe d'age: </b>
          {{ childAgeGroup }}
          <span v-if="child.ageGroup && individual.birthDate">
            (âge réel: {{ individual.birthDate | age }})
          </span>
          <div v-if="child.ageGroupFrom || child.ageGroupTo">
            Pour les séances
            <span v-if="child.ageGroupFrom">à partir du {{ child.ageGroupFrom | dateToString }} </span>
            <span v-if="child.ageGroupTo"> jusqu'au {{ child.ageGroupTo | dateToString }}</span>
          </div>
        </div>
        <div class="field-line" v-if="!child.hideDiscountPercentage">
          <b><i class="fas fa-percent" /> Réduction en %: </b>
          <span v-if="child.discountPercentage">{{ child.discountPercentage }}%</span>
          <span v-else>-</span>
        </div>
        <div class="field-line" v-if="!child.hideSpecialTariff">
          <b><i class="fas fa-euro-sign" /> Tarif spécial: </b>
            <span v-if="child.specialTariff.id">{{ child.specialTariff.name }}</span>
            <span v-else>-</span>
        </div>
        <div class="field-line" v-if="!child.hideWelfareCategory">
          <b><i class="fas fa-wave-square" /> Aide aux vacances: </b>
            <span v-if="child.welfareCategory.id">{{ child.welfareCategory.name }}</span>
            <span v-else>-</span>
        </div>
        <div class="field-line" v-if="!child.hideChildWarnings">
          <div>
            <b><i class="fa fa-exclamation-triangle"></i> Message enfant: </b>
          </div>
          <div class="child-warnings" :class="{'child-warnings-active': child.warnings}">
            {{ child.warnings|defaultValue('-') }}
          </div>
        </div>
        <div class="field-line">
          <div>
            <b><i class="fa fa-info-circle"></i> Handicap (Déclaration CAF):</b>
          </div>
          <div v-if="!child.hidePersonalHelp" class="help-indicator" :class="{'help-active': child.personalHelp}">
            PAI lié au handicap: <b>{{ child.personalHelp|yesno }}</b>
          </div>
          <div class="help-indicator" :class="{'help-active': child.handicapHelp}">
            Allocation Éducation Enfant Handicapé:
            <b>{{ child.handicapHelp|yesno }}</b>
              <b v-if="child.handicapHelp && child.handicapHelpStartsOn">
                du {{ child.handicapHelpStartsOn | dateToString }}
              </b>
              <b v-if="child.handicapHelp && child.handicapHelpEndsOn">
                jusqu'au {{ child.handicapHelpEndsOn | dateToString }}
              </b>
          </div>
        </div>
        <fields-detail
          v-if="member"
          :show-individual="true"
          :role="member.role"
          :individual="member.individual"
          :included="true"
          :is-family="true"
        >
        </fields-detail>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import store from '@/store'
import FieldsDetail from '@/components/Fields/FieldsDetail'
import { BackendMixin } from '@/mixins/backend'
import { makeChild, makeSpecialTariff, makeWelfareCategory } from '@/types/families'
import { makeSchoolLevel, makeSchoolYear } from '@/types/schools'
import { BackendApi } from '@/utils/http'
import { getBaseAge } from '@/utils/dates'
import { dateToString, pluralize } from '@/filters/texts'

export default {
  name: 'child-detail',
  mixins: [BackendMixin],
  components: { FieldsDetail, },
  props: {
    member: Object,
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      child: null,
      childData: null,
      tariffs: [],
      welfareCategories: [],
      schoolLevels: [],
      schoolYears: [],
      forceSave: false,
    }
  },
  created() {
    this.child = makeChild({})
    this.loadChild()
  },
  computed: {
    individual() {
      return this.member.individual
    },
    schools() {
      return store.getters.schools
    },
    showForm() {
      return store.getters.editMode === 'child'
    },
    editDisabled() {
      return store.getters.editMode !== ''
    },
    childAgeGroup() {
      if (this.child && this.child.ageGroup) {
        return '' + this.child.ageGroup + ' ' + pluralize(this.child.ageGroup, 'an')
      } else {
        return '-'
      }
    },
  },
  watch: {
    member: function() {
      this.loadChild()
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['setEditMode']),
    async onEdit() {
      await this.loadTariffs()
      await this.loadWelfareCategories()
      await this.loadSchoolLevels()
      await this.loadSchoolYears()
      this.childData = { ...this.child, }
      // Init SchoolClass
      if (this.childData.schoolClass) {
        for (const school of this.schools) {
          for (let schoolClass of school.schoolClasses) {
            if (this.child.schoolClass.id === schoolClass.id) {
              this.childData.schoolClass = schoolClass
              break
            }
          }
        }
      } else {
        this.childData.schoolClass = null
      }
      for (const schoolLevel of this.schoolLevels) {
        if (this.child.schoolLevel) {
          if (this.child.schoolLevel.id === schoolLevel.id) {
            this.childData.schoolLevel = schoolLevel
            break
          }
        }
      }
      this.setEditMode('child')
    },
    onCancel() {
      this.setEditMode('')
    },
    async loadChild() {
      let backendApi = null
      let url = '/api/families/children/' + this.individual.id + '/'
      backendApi = new BackendApi('get', url)
      try {
        let resp = await backendApi.callApi()
        this.child = makeChild(resp.data)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadTariffs() {
      this.tariffs = []
      let backendApi = null
      let url = '/api/families/tariffs/'
      backendApi = new BackendApi('get', url)
      try {
        let resp = await backendApi.callApi()
        if (resp.data.length > 0) {
          this.tariffs.push(makeSpecialTariff()) // empty
          this.tariffs = this.tariffs.concat(resp.data.map(elt => makeSpecialTariff(elt)))
        }
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadSchoolLevels() {
      this.schoolLevels = []
      let backendApi = null
      let url = '/api/school-levels/'
      backendApi = new BackendApi('get', url)
      try {
        let resp = await backendApi.callApi()
        if (resp.data.length > 0) {
          this.schoolLevels.push(makeSchoolLevel()) // empty
          this.schoolLevels = this.schoolLevels.concat(resp.data.map(elt => makeSchoolLevel(elt)))
        }
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadSchoolYears() {
      let url = '/api/active-school-years/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.schoolYears = resp.data.map(
          elt => makeSchoolYear(elt)
        )
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadWelfareCategories() {
      this.welfareCategories = []
      let backendApi = null
      let url = '/api/families/welfare-categories/'
      backendApi = new BackendApi('get', url)
      try {
        let resp = await backendApi.callApi()
        if (resp.data.length > 0) {
          this.welfareCategories.push(makeWelfareCategory()) // empty
          this.welfareCategories = this.welfareCategories.concat(resp.data.map(elt => makeWelfareCategory(elt)))
        }
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async onSave(forced) {
      if (!forced) {
        this.cancelMemberEdition()
      }
      let discountPercentage = this.childData.discountPercentage || null
      if (discountPercentage) {
        discountPercentage = Math.round(discountPercentage * 100) / 100
      }
      const ageGroupFrom = this.childData.ageGroup ? this.childData.ageGroupFrom : null
      const ageGroupTo = this.childData.ageGroup ? this.childData.ageGroupTo : null
      let helpsStartsOn = null
      let helpsEndsOn = null
      if (this.childData.handicapHelp) {
        helpsStartsOn = this.childData.handicapHelpStartsOn
        helpsStartsOn = helpsStartsOn ? dateToString(helpsStartsOn, 'YYYY-MM-DD') : null
        helpsEndsOn = this.childData.handicapHelpEndsOn
        helpsEndsOn = helpsEndsOn ? dateToString(helpsEndsOn, 'YYYY-MM-DD') : null
      }
      const data = {
        school_class: (this.childData.schoolClass ? this.childData.schoolClass.id : 0) || null,
        age_group: this.childData.ageGroup || null,
        age_group_from: ageGroupFrom || null,
        age_group_to: ageGroupTo || null,
        discount_percentage: discountPercentage,
        warnings: this.childData.warnings,
        special_tariff: this.childData.specialTariff.id || null,
        welfare_category: this.childData.welfareCategory.id || null,
        personal_help: this.childData.personalHelp,
        handicap_help: this.childData.handicapHelp,
        handicap_help_starts_on: helpsStartsOn,
        handicap_help_ends_on: helpsEndsOn,
        school_level: this.childData.schoolLevel.id || null,
      }
      let backendApi = null
      let url = '/api/families/children/' + this.individual.id + '/'
      backendApi = new BackendApi('patch', url)
      try {
        let resp = await backendApi.callApi(data)
        this.child = makeChild(resp.data)
        await this.addSuccess('La personne a été enregistrée')
        if (forced) {
          this.forceSave = true
        }
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    onFieldsSaved() {
      this.forceSave = false
      this.setEditMode('')
    },
    calculateSchoolLevel(startYear) {
      if (this.individual.birthDate) {
        const age = getBaseAge(this.individual.birthDate, startYear)
        for (const schoolLevel of this.schoolLevels) {
          if (schoolLevel.age === age) {
            this.childData.schoolLevel = schoolLevel
            break
          }
        }
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.child-warnings {
  background: #e0e0e0;
  padding: 5px;
}
.child-warnings-active {
  background: #d9be48;
}
.help-indicator {
  margin: 5px 0;
  padding: 2px;
}
.help-active {
  background: #d9be48;
}
.reset-age-group {
  text-align: right;
  margin-top: -20px;
  font-size: 12px;
}
</style>
